<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div :class="'modal-dialog modal-dialog-centered ' + size">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ title }}
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <Svg/>
          <div class="align-items-center justify-content-between text-center">
            <div class="text-dark-success mb-3" v-if="type === 'success'">
              <svg class="bi flex-shrink-0" width="40" height="40"><use xlink:href="#check-circle-fill"></use></svg>
            </div>
            <div class="text-dark-danger mb-3" v-if="type === 'danger'">
              <svg class="bi flex-shrink-0" width="40" height="40"><use xlink:href="#exclamation-triangle-fill"></use></svg>
            </div>
            <div>
              <span class="w-100">{{ message }}</span>
              <small v-if="messageDetail" >{{ messageDetail }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button :id="id + 'Open'" class="d-none" :data-bs-target="'#' + id" data-bs-toggle="modal">Open</button>
</template>

<script>
import Svg from "@/components/Svg";

export default {
  name: "MessageModal",
  props: {
    title: {
      type: String,
      default: "Thông báo"
    },
    message: String,
    messageDetail: String,
    id: String,
    size: String,
    type: String
  },
  components: {
    Svg
  }
}
</script>
