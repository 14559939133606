<template>
  <div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-5 col-md-8 py-8 py-xl-0">
        <div class="card shadow ">
          <div class="card-body p-6">
            <div class="mb-4 text-center">
              <router-link :to="{name: 'Home'}" v-if="school.thumbnail">
                <img :src="school.thumbnail.link" class="mb-4 avatar-xxl rounded bg-white object-fit-cover" alt="">
              </router-link>
              <h1 class="mb-1 fw-bold">Đăng nhập</h1>
              <span>Bạn chưa có tài khoản?&nbsp;
                 <router-link :to="{name: 'Register'}" class="ms-1">Đăng ký</router-link>
              </span>
            </div>
            <form v-on:submit.prevent="login">
              <div class="mb-3">
                <label for="identity" class="form-label">Email/SĐT</label>
                <input type="text" v-model="identity" id="identity" class="form-control" name="identity" placeholder="Email hoặc SĐT"
                       required>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Mật khẩu</label>
                <input type="password" v-model="password" id="password" class="form-control" name="password" placeholder=""
                       required>
              </div>
              <div class="d-lg-flex justify-content-between align-items-center mb-4">
                <div>
                  <router-link :to="{name: 'ForgotPassword'}">
                    Quên mật khẩu?
                  </router-link>
                </div>
              </div>
              <div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary ">Đăng nhập</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="loginModal" title="Đăng nhập" :type="messageModalType" :message="messageModalMessage" />
</template>

<script>
import ApiService from "@/core/services/api.service";
import {saveToken} from "@/core/services/jwt.service";
import Modal from "@/components/Modal";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'Login',
  components: {
    Modal
  },
  props: {
    school: Object
  },
  data() {
    return {
      identity: "",
      password: "",
      messageModalType: "",
      messageModalMessage: "",
    }
  },
  methods: {
    login() {
      let query = `mutation($identity: String!, $password: String!) {
        login(identity: $identity, password: $password) {
          access_token
        }
      }`;

      ApiService.graphql(query, {identity: this.identity, password: this.password})
          .then(({data}) => {
            if (data.data && data.data.login) {
              saveToken(data.data.login.access_token);
              window.location = "/";
            } else {
              this.messageModalMessage = "Sai email/sđt hoặc mật khẩu";
              this.messageModalType = "danger";
              openModal("loginModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("loginModal");
          });
    }
  }
}
</script>
